import React, { useEffect, useState } from "react";
import "./Css/Login.css";
import { useNavigate } from "react-router-dom";
import { IconButton, Snackbar, Modal, Box, Typography } from "@mui/material";
import { ArrowBack, Email, Lock } from "@mui/icons-material";
import { Alert } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { db } from "./firebase.js";
import { collection, query, where, getDocs } from "firebase/firestore";
import pat from "./../src/Images/pat_image.jpg";
import mir from "./../src/Images/mir_image.jpg";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const handleBackClick = () => {
    navigate("/");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleOptionClick = (path) => {
    setOpenModal(false);
    navigate(path);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const q = query(
      collection(db, "Usuarios"),
      where("email", "==", email),
      where("password", "==", password)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      localStorage.setItem("userName", userData.name);
      localStorage.setItem("userRole", userData.role);
      setSuccessMessage(`Bienvenido ${userData.name}`);
      setOpenSnackbar(true);
      setTimeout(() => {
        if (userData.role === "admin") {
          navigate("/admin-panel");
        } else {
          setOpenModal(true);
        }
      }, 2000);
    } else {
      setErrorMessage("Correo o contraseña incorrectos");
      setOpenErrorSnackbar(true);
    }
  };

  return (
    <div className="login-page">
      <div className="login-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="login-container" data-aos="fade-up">
        <div className="login-image" data-aos="fade-right"></div>
        <div className="login-form" data-aos="fade-left">
          <h2>Ingresar</h2>
          <form onSubmit={handleSubmit}>
            <div
              className="login-form-group"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <input
                type="email"
                id="email"
                placeholder="Correo electrónico"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Email className="icon" />
            </div>
            <div
              className="login-form-group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <input
                type="password"
                id="password"
                placeholder="Contraseña"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Lock className="icon" />
            </div>
            <button type="submit" data-aos="fade-up" data-aos-delay="300">
              Ingresar
            </button>
            <div
              className="login-form-links"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <a href="/forgotPassword">¿Olvidaste tu contraseña?</a>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#4CAF50" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F44336" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        sx={{
          backdropFilter: "blur(25px)",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "75%", md: "45%" },
            bgcolor: "background.paper",
            border: "none",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              mb: 4,
              fontWeight: "bold",
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
            }}
          >
            ¿Qué desea agregar?
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid #ccc",
                borderRadius: 2,
                padding: 2,
                cursor: "pointer",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleOptionClick("/directorypat")}
            >
              <Typography
                variant="h6"
                align="center"
                sx={{
                  mb: 2,
                  fontStyle: "italic",
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem" },
                }}
              >
                Plan Anual de Trabajo
              </Typography>
              <img
                src={pat}
                alt="Plan Anual de Trabajo"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "cover",
                  borderRadius: 4,
                }}
              />
            </Box>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid #ccc",
                borderRadius: 2,
                padding: 2,
                cursor: "pointer",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleOptionClick("/directorymir")}
            >
              <Typography
                variant="h6"
                align="center"
                sx={{
                  mb: 2,
                  fontStyle: "italic",
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem" },
                }}
              >
                Matriz de Indicadores para Resultados
              </Typography>
              <img
                src={mir}
                alt="Matriz de Indicadores"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "cover",
                  borderRadius: 4,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Login;
