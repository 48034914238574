import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Components/Header.js";
import EjeR1 from "./Images/EjeR1.jpg";
import EjeR2 from "./Images/EjeR2.jpg";
import EjeR3 from "./Images/EjeR3.jpg";
import EjeR4 from "./Images/EjeR4Tranversal.jpg";
import EjeT1 from "./Images/EjeT1.jpg";
import EjeT2 from "./Images/EjeT2.jpg";
import EjeT3 from "./Images/EjeT3.jpg";
import "./Css/Directorymir.css";

function Directorymir() {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="directory-page" data-aos="fade-down">
      <Header name={name} handleLogout={handleLogout} />
      <h1 className="directory-title">
        EJES RECTORES
      </h1>
      <div className="directory-content">
        <div className="card" onClick={() => handleCardClick("/EjeR1MIR")}>
          <h2>EJE RECTOR 1. SOCIAL.</h2>
          <h3>MUNICIPIO SOCIALMENTE RESPONSABLE, SOLIDARIO E INCLUYENTE</h3>
          <img src={EjeR1} alt="Social" />
        </div>
        <div
          className="card"
          onClick={() => handleCardClick("/EjeR2MIR")}
        >
          <h2>EJE RECTOR 2. ECONÓMICO.</h2>
          <h3>MUNICIPIO COMPETITIVO, PRODUCTIVO E INNOVADOR</h3>
          <img src={EjeR2} alt="Económico" />
        </div>
        <div
          className="card"
          onClick={() => handleCardClick("/EjeR3MIR")}
        >
          <h2>EJE RECTOR 3. TERRITORIAL.</h2>
          <h3>MUNICIPIO ORDENADO, SUSTENTABLE Y RESILIENTE</h3>
          <img src={EjeR3} alt="Territorial" />
        </div>
        <div
          className="card"
          onClick={() => handleCardClick("/EjeR4MIR")}
        >
          <h2>EJE RECTOR 4. SEGURIDAD.</h2>
          <h3>MUNICIPIO CON SEGURIDAD Y JUSTICIA</h3>
          <img src={EjeR4} alt="Seguridad" />
        </div>
      </div>
      <h1 className="directory-title">
        EJES TRANSVERSALES
      </h1>
      <div className="directory-content">
        <div
          className="card"
          onClick={() => handleCardClick("/EjeT1MIR")}
        >
          <h2>EJE TRANSVERSAL 1.</h2>
          <h3>IGUALDAD DE GÉNERO</h3>
          <img src={EjeT1} alt="Igualdad" />
        </div>
        <div
          className="card"
          onClick={() => handleCardClick("/EjeT2MIR")}
        >
          <h2>EJE TRANSVERSAL 2.</h2>
          <h3>GOBIERNO MODERNO, CAPAZ Y RESPONSABLE</h3>
          <img src={EjeT2} alt="Moderno" />
        </div>
        <div
          className="card"
          onClick={() => handleCardClick("/EjeT3MIR")}
        >
          <h2>EJE TRANSVERSAL 3.</h2>
          <h3>TECNOLOGÍA Y COORDINACIÓN PARA EL BUEN GOBIERNO</h3>
          <img src={EjeT3} alt="Tecnología" />
        </div>
      </div>
    </div>
  );
}

export default Directorymir;
