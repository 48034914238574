import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import Header from "./Components/Header.js";
import "./Css/EjesRectoresPAT.css";
import { db } from "./firebase.js";
import { collection, query, getDocs, doc, getDoc } from "firebase/firestore";

function XaliscoDesarrollo() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lineaAccionE2AData, setLineaAccionE2AData] = useState([]);
  const [lineaAccionE2BData, setLineaAccionE2BData] = useState([]);
  const [lineaAccionE2CData, setLineaAccionE2CData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDependencia, setSelectedDependencia] = useState("");
  const [selectedDocPath, setSelectedDocPath] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [ejeRectorPMD, setEjeRectorPMD] = useState("");
  const [lineaAccionPMD, setLineaAccionPMD] = useState("");
  const [programaPMD, setProgramaPMD] = useState("");
  const [patListDialogOpen, setPatListDialogOpen] = useState(false);
  const [patDocuments, setPatDocuments] = useState([]);
  const [menuAction, setMenuAction] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }

    const fetchData = async () => {
      const qE2A = query(collection(db, "EjeRector2", "E2-A", "LineaAccion"));
      const querySnapshotE2A = await getDocs(qE2A);
      const dataE2A = querySnapshotE2A.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataE2A.sort((a, b) => {
        const numA = parseInt(a.descripcion.split(".")[0], 10);
        const numB = parseInt(b.descripcion.split(".")[0], 10);
        return numA - numB;
      });
      setLineaAccionE2AData(dataE2A);

      const qE2B = query(collection(db, "EjeRector2", "E2-B", "LineaAccion"));
      const querySnapshotE2B = await getDocs(qE2B);
      const dataE2B = querySnapshotE2B.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataE2B.sort((a, b) => {
        const numA = parseInt(a.descripcion.split(".")[0], 10);
        const numB = parseInt(b.descripcion.split(".")[0], 10);
        return numA - numB;
      });
      setLineaAccionE2BData(dataE2B);

      const qE2C = query(collection(db, "EjeRector2", "E2-C", "LineaAccion"));
      const querySnapshotE2C = await getDocs(qE2C);
      const dataE2C = querySnapshotE2C.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataE2C.sort((a, b) => {
        const numA = parseInt(a.descripcion.split(".")[0], 10);
        const numB = parseInt(b.descripcion.split(".")[0], 10);
        return numA - numB;
      });
      setLineaAccionE2CData(dataE2C);
    };

    fetchData();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorypat");
  };

  const handleCardClickE2A = async (event, dependencia, docId) => {
    setSelectedDependencia(dependencia);
    setSelectedDocPath(`EjeRector2/E2-A/LineaAccion/${docId}`);
    setAnchorEl(event.currentTarget);

    const docRef = doc(db, `EjeRector2/E2-A/LineaAccion`, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setEjeRectorPMD(data.ejeRectorPMD || "");
      setLineaAccionPMD(data.lineaAccionPMD || "");
      setProgramaPMD(data.programaPMD || "");
    }
  };

  const handleCardClickE2B = async (event, dependencia, docId) => {
    setSelectedDependencia(dependencia);
    setSelectedDocPath(`EjeRector2/E2-B/LineaAccion/${docId}`);
    setAnchorEl(event.currentTarget);

    const docRef = doc(db, `EjeRector2/E2-B/LineaAccion`, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setEjeRectorPMD(data.ejeRectorPMD || "");
      setLineaAccionPMD(data.lineaAccionPMD || "");
      setProgramaPMD(data.programaPMD || "");
    }
  };

  const handleCardClickE2C = async (event, dependencia, docId) => {
    setSelectedDependencia(dependencia);
    setSelectedDocPath(`EjeRector2/E2-C/LineaAccion/${docId}`);
    setAnchorEl(event.currentTarget);

    const docRef = doc(db, `EjeRector2/E2-C/LineaAccion`, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setEjeRectorPMD(data.ejeRectorPMD || "");
      setLineaAccionPMD(data.lineaAccionPMD || "");
      setProgramaPMD(data.programaPMD || "");
    }
  };

  const handleMenuClose = async (action) => {
    setAnchorEl(null);
    setMenuAction(action); 
    if (action === "add") {
      navigate("/formulario-pat", {
        state: {
          dependencia: selectedDependencia,
          docPath: selectedDocPath,
          ejeRectorPMD,
          lineaAccionPMD,
          programaPMD,
        },
      });
    } else if (action === "view" || action === "progress") {
      const patRef = collection(db, selectedDocPath, "Pat");
      const patSnapshot = await getDocs(patRef);
      const patDocuments = patSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (patDocuments.length === 0) {
        setSnackbarOpen(true);
      } else if (patDocuments.length === 1) {
        const patId = patDocuments[0].id;
        const route =
          action === "view" ? `/ver-pat/${patId}` : `/avance-pat/${patId}`;
        navigate(route, {
          state: { dependencia: selectedDependencia, docPath: selectedDocPath },
        });
      } else if (patDocuments.length > 1) {
        setPatDocuments(patDocuments);
        setPatListDialogOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handlePatListItemClick = (patId) => {
    if (menuAction === "view") {
      navigate(`/ver-pat/${patId}`, {
        state: {
          dependencia: selectedDependencia,
          docPath: selectedDocPath,
          ejeRectorPMD,
          lineaAccionPMD,
          programaPMD,
        },
      });
    } else if (menuAction === "progress") {
      navigate(`/avance-pat/${patId}`, {
        state: {
          dependencia: selectedDependencia,
          docPath: selectedDocPath,
          ejeRectorPMD,
          lineaAccionPMD,
          programaPMD,
        },
      });
    }
    setPatListDialogOpen(false);
  };

  return (
    <div className="ejes-rectores-page">
      <Header name={name} handleLogout={handleLogout} />
      <div className="ejes-rectores-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="ejes-rectores-content-container" data-aos="fade-up">
        <h1>EJE RECTOR. E2. XALISCO EN DESARROLLO</h1>
        <h2>
          III. Línea de acción. E2-A. Obras, servicios públicos y vivienda
        </h2>
        <div className="ejes-rectores-card-container">
          {lineaAccionE2AData.map((item, index) => (
            <div
              className="ejes-rectores-card"
              key={index}
              onClick={(e) => handleCardClickE2A(e, item.dependencia, item.id)}
            >
              <strong>{item.dependencia}</strong>
              <p>{item.descripcion}</p>
            </div>
          ))}
        </div>
        <h2>IV. Línea de acción. E2-B. Protección al medio ambiente</h2>
        <div className="ejes-rectores-card-container">
          {lineaAccionE2BData.map((item, index) => (
            <div
              className="ejes-rectores-card"
              key={index}
              onClick={(e) => handleCardClickE2B(e, item.dependencia, item.id)}
            >
              <strong>{item.dependencia}</strong>
              <p>{item.descripcion}</p>
            </div>
          ))}
        </div>
        <h2>V. Línea de acción. E2-C. Movilidad sustentable</h2>
        <div className="ejes-rectores-card-container">
          {lineaAccionE2CData.map((item, index) => (
            <div
              className="ejes-rectores-card"
              key={index}
              onClick={(e) => handleCardClickE2C(e, item.dependencia, item.id)}
            >
              <strong>{item.dependencia}</strong>
              <p>{item.descripcion}</p>
            </div>
          ))}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(null)}
      >
        <MenuItem onClick={() => handleMenuClose("view")}>
          Ver Programa Anual de Trabajo
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("add")}>
          Agregar Programa Anual de Trabajo
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("progress")}>
          Agregar Avance al Programa Anual de Trabajo
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(null)}>Cancelar</MenuItem>
      </Menu>
      <Dialog
        open={patListDialogOpen}
        onClose={() => setPatListDialogOpen(false)}
      >
        <DialogTitle>
          Selecciona el Programa Anual de Trabajo que desea ver:
        </DialogTitle>
        <DialogContent>
          <List>
            {patDocuments.map((pat) => (
              <ListItem
                button
                key={pat.id}
                onClick={() => handlePatListItemClick(pat.id)}
              >
                <ListItemText primary={pat.nombrePAT} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPatListDialogOpen(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          No hay ningún PAT en este Programa.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default XaliscoDesarrollo;
