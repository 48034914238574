import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "./firebase.js";
import { doc, getDoc, setDoc } from "firebase/firestore";
import jsPDF from "jspdf";
import Header from "./Components/Header.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Css/AvancePAT.css";
import { ArrowBack } from "@mui/icons-material";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/logo_impl.png";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditableField from "./Components/EditableField";

function AvancePAT() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependencia, docPath } = location.state || {};
  const { patId } = useParams();
  const [patData, setPatData] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [programaPMD, setProgramaPMD] = useState("");
  const [nombrePAT, setNombrePAT] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }

    const fetchPatData = async () => {
      if (docPath && patId) {
        const patDocRef = doc(db, `${docPath}/Pat`, patId);
        const patDocSnap = await getDoc(patDocRef);
        if (patDocSnap.exists()) {
          const data = { id: patDocSnap.id, ...patDocSnap.data() };
          setPatData(data);

          if (data.programaPMD) {
            setProgramaPMD(data.programaPMD);
          }
        }
      }
    };

    const fetchNombrePAT = async () => {
      const patDocRef = doc(db, `${docPath}/Pat`, patId);
      const docSnap = await getDoc(patDocRef);
      if (docSnap.exists()) {
        setNombrePAT(docSnap.data().nombrePAT);
      } else {
        console.log("No such document!");
      }
    };

    const checkEditableDates = async () => {
      const docRef = doc(db, "admin", "editDates");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const { startDate, endDate } = docSnap.data();
        const currentDate = new Date().toISOString().split("T")[0];
        if (currentDate >= startDate && currentDate <= endDate) {
          setIsEditable(true);
        }
      }
    };

    fetchPatData();
    checkEditableDates();
    fetchNombrePAT();
  }, [docPath, patId, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorypat");
  };

  const handleSave = (index, field, newValue) => {
    const updatedPatData = { ...patData };
    const fieldPath = field.split(".");
    if (fieldPath.length === 2) {
      updatedPatData.actividades[index][fieldPath[0]][fieldPath[1]] = newValue;
    } else {
      updatedPatData.actividades[index][field] = newValue;
    }

    const { totalAcumulado, avanceFisicoVsOriginal } = calculateTotals(
      updatedPatData.actividades[index]
    );
    updatedPatData.actividades[index].totalAcumulado = totalAcumulado;
    updatedPatData.actividades[
      index
    ].avanceFisicoVsOriginal = `${avanceFisicoVsOriginal.toFixed(2)}%`;

    setPatData(updatedPatData);
  };

  const handleSaveToDatabase = async () => {
    if (!isEditable) {
      setSnackbarMessage("AÚN NO SON LAS FECHAS PARA AGREGAR AVANCES");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (docPath && patData) {
      const patDocRef = doc(db, `${docPath}/Pat`, patData.id);
      await setDoc(patDocRef, patData);
      setSnackbarMessage("¡Avance guardado exitosamente!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      generateAndDownloadPDF();

      setTimeout(() => {
        navigate("/directorypat");
      }, 3000);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const generateAndDownloadPDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    let yOffset = 5;

    const logoWidthIzquierda = 28;
    const logoWidthDerecha = 40;
    const logoHeightIzquierda = 30;
    const logoHeightDerecha = 15;

    doc.addImage(
      logoIzquierda,
      "PNG",
      3,
      4,
      logoWidthIzquierda,
      logoHeightIzquierda
    );
    doc.addImage(
      logoDerecha,
      "PNG",
      pageWidth - logoWidthDerecha - 10,
      10,
      logoWidthDerecha,
      logoHeightDerecha
    );
    yOffset += Math.max(logoHeightIzquierda, logoHeightDerecha) + 5;

    const addCenteredText = (text, size, bold = false) => {
      doc.setFontSize(size);
      if (bold) doc.setFont("helvetica", "bold");
      else doc.setFont("helvetica", "normal");
      const textWidth =
        (doc.getStringUnitWidth(text) * size) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, yOffset);
      yOffset += 7;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 20;
      }
    };

    addCenteredText("H AYUNTAMIENTO DE XALISCO", 14, true);
    addCenteredText(
      `UNIDAD RESPONSABLE "${dependencia.toUpperCase()}"`,
      14,
      true
    );
    addCenteredText("PROGRAMA ANUAL DE TRABAJO", 14, true);
    addCenteredText("COMPROBANTE DE AVANCE", 14, true);
    yOffset += 10;

    const tableData = [
      ["Fecha", formattedDate],
      ["Hora", formattedTime],
      ["Usuario", name],
      ["Dependencia", dependencia.toUpperCase()],
      ["Programa", programaPMD || "No definido"],
      ["Nombre PAT", nombrePAT || "No definido"],
      ["Mensaje", "¡Se ha agregado un avance exitosamente!"],
    ];

    doc.autoTable({
      startY: yOffset,
      head: [["Campo", "Valor"]],
      body: tableData,
      styles: { font: "helvetica", fontSize: 13 },
      headStyles: { fillColor: [0, 0, 0] },
      columnStyles: { 0: { cellWidth: 40 }, 1: { cellWidth: 140 } },
      margin: { top: 10 },
    });

    doc.save(`Comprobante_Avance_${dependencia.toUpperCase()}.pdf`);
  };

  const evaluateAndColorize = (realizado, planificado) => {
    if (!realizado || !planificado)
      return { backgroundColor: "white", color: "black" };
    const realizadoNum = parseInt(realizado, 10);
    const planificadoNum = parseInt(planificado, 10);

    if (realizadoNum >= planificadoNum) {
      return { backgroundColor: "green", color: "white" };
    } else if (realizadoNum >= planificadoNum * 0.5) {
      return { backgroundColor: "yellow", color: "black" };
    } else {
      return { backgroundColor: "red", color: "white" };
    }
  };

  const calculateTotals = (actividad) => {
    const {
      primerTrimestre,
      segundoTrimestre,
      tercerTrimestre,
      cuartoTrimestre,
    } = actividad.realizado;
    const metaAnual = Number(actividad.metaAnual) || 1;
    const totalAcumulado =
      (Number(primerTrimestre) || 0) +
      (Number(segundoTrimestre) || 0) +
      (Number(tercerTrimestre) || 0) +
      (Number(cuartoTrimestre) || 0);
    const avanceFisicoVsOriginal = (totalAcumulado / metaAnual) * 100;

    return { totalAcumulado, avanceFisicoVsOriginal };
  };

  return (
    <div className="avance-pat-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="avance-pat-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="zoom-in">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="avance-pat-title" data-aos="zoom-in">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>PROGRAMA ANUAL DE TRABAJO</h1>
        <h3>DEPENDENCIA XLII AYUNTAMIENTO DE XALISCO</h3>
        <h3>AGREGAR AVANCE AL PAT DE {dependencia.toUpperCase()}</h3>
      </div>
      <div className="avance-pat-content" data-aos="fade-left" delay="1000">
        {patData ? (
          <div className="avance-pat-grid">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveToDatabase}
            >
              Agregar avance
            </Button>
            <div className="avance-pat-category">
              <h3>Actividades:</h3>
              {patData?.actividades &&
                patData?.actividades.map((actividad, index) => (
                  <Accordion key={index} className="pat-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="pat-accordion-summary"
                    >
                      <Typography>Actividad No. {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="pat-accordion-details">
                      <div className="actividad">
                        <div className="pat-grid-item">
                          Descripción: {actividad.descripcion}
                        </div>
                        <div className="pat-grid-item">
                          Fundamentación Jurídica:{" "}
                          {actividad.fundamentacionJuridica}
                        </div>
                        <div className="pat-grid-item">
                          Meta Anual: {actividad.metaAnual}
                        </div>
                        <Accordion className="pat-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="pat-accordion-summary"
                          >
                            <Typography>CALENDARIO</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="pat-accordion-details">
                            <div className="trimestre-container">
                              <div className="pat-grid-item">
                                1er. TRIMESTRE:{" "}
                                {actividad.calendario.primerTrimestre}
                              </div>
                              <div className="pat-grid-item">
                                2do. TRIMESTRE:{" "}
                                {actividad.calendario.segundoTrimestre}
                              </div>
                              <div className="pat-grid-item">
                                3er. TRIMESTRE:{" "}
                                {actividad.calendario.tercerTrimestre}
                              </div>
                              <div className="pat-grid-item">
                                4o. TRIMESTRE:{" "}
                                {actividad.calendario.cuartoTrimestre}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="pat-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="pat-accordion-summary"
                          >
                            <Typography>REALIZADO</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="pat-accordion-details">
                            <div className="trimestre-container">
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.primerTrimestre,
                                  actividad.calendario.primerTrimestre
                                )}
                              >
                                1er. TRIMESTRE:{" "}
                                <EditableField
                                  value={actividad.realizado.primerTrimestre}
                                  onSave={(newValue) =>
                                    handleSave(
                                      index,
                                      "realizado.primerTrimestre",
                                      newValue
                                    )
                                  }
                                  editable={isEditable}
                                  maxLimit={
                                    Number(
                                      actividad.calendario.primerTrimestre
                                    ) || 0
                                  }
                                />
                              </div>
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.segundoTrimestre,
                                  actividad.calendario.segundoTrimestre
                                )}
                              >
                                2do. TRIMESTRE:{" "}
                                <EditableField
                                  value={actividad.realizado.segundoTrimestre}
                                  onSave={(newValue) =>
                                    handleSave(
                                      index,
                                      "realizado.segundoTrimestre",
                                      newValue
                                    )
                                  }
                                  editable={isEditable}
                                  maxLimit={
                                    Number(
                                      actividad.calendario.segundoTrimestre
                                    ) || 0
                                  }
                                />
                              </div>
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.tercerTrimestre,
                                  actividad.calendario.tercerTrimestre
                                )}
                              >
                                3er. TRIMESTRE:{" "}
                                <EditableField
                                  value={actividad.realizado.tercerTrimestre}
                                  onSave={(newValue) =>
                                    handleSave(
                                      index,
                                      "realizado.tercerTrimestre",
                                      newValue
                                    )
                                  }
                                  editable={isEditable}
                                  maxLimit={
                                    Number(
                                      actividad.calendario.tercerTrimestre
                                    ) || 0
                                  }
                                />
                              </div>
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.cuartoTrimestre,
                                  actividad.calendario.cuartoTrimestre
                                )}
                              >
                                4o. TRIMESTRE:{" "}
                                <EditableField
                                  value={actividad.realizado.cuartoTrimestre}
                                  onSave={(newValue) =>
                                    handleSave(
                                      index,
                                      "realizado.cuartoTrimestre",
                                      newValue
                                    )
                                  }
                                  editable={isEditable}
                                  maxLimit={
                                    Number(
                                      actividad.calendario.cuartoTrimestre
                                    ) || 0
                                  }
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="pat-grid-item">
                          TOTAL ACUMULADO:{" "}
                          <EditableField
                            value={actividad.totalAcumulado}
                            onSave={(newValue) =>
                              handleSave(index, "totalAcumulado", newValue)
                            }
                            editable={false}
                            defaultText=""
                          />
                        </div>
                        <div className="pat-grid-item">
                          % DE AVANCE FÍSICO VS ORIGINAL:{" "}
                          <EditableField
                            value={actividad.avanceFisicoVsOriginal}
                            onSave={(newValue) =>
                              handleSave(
                                index,
                                "avanceFisicoVsOriginal",
                                newValue
                              )
                            }
                            editable={false}
                            defaultText=""
                          />
                        </div>
                        <div className="pat-grid-item">
                          OBJETIVO DE LA ACTIVIDAD:{" "}
                          {actividad.objetivoActividad}
                        </div>
                        <div className="pat-grid-item">
                          OBSERVACIONES:{" "}
                          <EditableField
                            value={actividad.observaciones}
                            onSave={(newValue) =>
                              handleSave(index, "observaciones", newValue)
                            }
                            editable={isEditable}
                            isText={true}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        ) : (
          <p>Aún no se ha agregado ningún Programa Anual de Trabajo</p>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AvancePAT;
