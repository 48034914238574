import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "./firebase.js";
import { doc, getDoc, setDoc } from "firebase/firestore";
import jsPDF from "jspdf";
import Header from "./Components/Header.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Css/AvanceMIR.css";
import { ArrowBack } from "@mui/icons-material";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/LogoImplanNuevo.jpeg";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditableField from "./Components/EditableField";

function AvanceMIR() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependenciaPrograma, docPath } = location.state || {};
  const { mirId } = useParams();
  const [mirData, setMirData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [nombreMIR, setNombreMIR] = useState("");
  const [nombrePrograma, setNombrePrograma] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }

    const fetchMirData = async () => {
      if (docPath && mirId) {
        const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
        const mirDocSnap = await getDoc(mirDocRef);
        if (mirDocSnap.exists()) {
          const data = { id: mirDocSnap.id, ...mirDocSnap.data() };
          setMirData(data);
        }
      }
    };

    const fetchNombreMIR = async () => {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
      const docSnap = await getDoc(mirDocRef);
      if (docSnap.exists()) {
        setNombreMIR(docSnap.data().nombreMIR);
      } else {
        console.log("No such document!");
      }
    };

    const fetchProgramaMIR = async () => {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
      const docSnap = await getDoc(mirDocRef);
      if (docSnap.exists()) {
        setNombrePrograma(docSnap.data().nombrePrograma);
      } else {
        console.log("No such document!");
      }
    };

    const checkEditableDates = async () => {
      const docRef = doc(db, "admin", "editDates");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const { startDate, endDate } = docSnap.data();
        const currentDate = new Date().toISOString().split("T")[0];
        if (currentDate >= startDate && currentDate <= endDate) {
          setIsEditable(true);
        }
      }
    };

    fetchMirData();
    checkEditableDates();
    fetchNombreMIR();
    fetchProgramaMIR();
  }, [docPath, mirId, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const handleSave = (index, field, newValue) => {
    const updatedMirData = { ...mirData };
    const actividad = updatedMirData.actividades[index];

    if (field === "unidadMedida") {
      actividad.unidadMedidaActividad.unidadMedida = newValue;
    } else if (field === "frecuencia") {
      actividad.unidadMedidaActividad.frecuencia = newValue;
    } else if (field === "tipo") {
      actividad.tipoActividad.tipo = newValue;
    } else if (field === "dimensión") {
      actividad.tipoActividad.dimensión = newValue;
    } else if (field === "sentidoActividad") {
      actividad.sentidoActividad = newValue;
    } else if (field === "añoLineaBase") {
      actividad.lineaBaseActividad.añoLineaBase = newValue;
    } else if (field === "lineaBase") {
      actividad.lineaBaseActividad.lineaBase = newValue;
    } else if (field === "datosVariables1") {
      actividad.metasActividades.datosVariables1 = newValue;
    } else if (field === "datosVariables2") {
      actividad.metasActividades.datosVariables2 = newValue;
    } else if (field === "resultado") {
      actividad.metasActividades.resultado = newValue;
    } else if (field === "primerTrimestre.datos1") {
      actividad.primerTrimestre.datos1 = newValue;
    } else if (field === "primerTrimestre.datos2") {
      actividad.primerTrimestre.datos2 = newValue;
    } else if (field === "primerTrimestre.resultado") {
      actividad.primerTrimestre.resultado = newValue;
    } else if (field === "segundoTrimestre.datos1") {
      actividad.segundoTrimestre.datos1 = newValue;
    } else if (field === "segundoTrimestre.datos2") {
      actividad.segundoTrimestre.datos2 = newValue;
    } else if (field === "segundoTrimestre.resultado") {
      actividad.segundoTrimestre.resultado = newValue;
    } else if (field === "tercerTrimestre.datos1") {
      actividad.tercerTrimestre.datos1 = newValue;
    } else if (field === "tercerTrimestre.datos2") {
      actividad.tercerTrimestre.datos2 = newValue;
    } else if (field === "tercerTrimestre.resultado") {
      actividad.tercerTrimestre.resultado = newValue;
    } else if (field === "cuartoTrimestre.datos1") {
      actividad.cuartoTrimestre.datos1 = newValue;
    } else if (field === "cuartoTrimestre.datos2") {
      actividad.cuartoTrimestre.datos2 = newValue;
    } else if (field === "cuartoTrimestre.resultado") {
      actividad.cuartoTrimestre.resultado = newValue;
    }
    setMirData(updatedMirData);
  };

  const handleSaveToDatabase = async () => {
    if (!isEditable) {
      setSnackbarMessage("AÚN NO SON LAS FECHAS PARA AGREGAR AVANCES");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (docPath && mirData) {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirData.id);
      try {
        await setDoc(mirDocRef, mirData);
        setSnackbarMessage("¡Avance guardado exitosamente!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        generateAndDownloadPDF();

        setTimeout(() => {
          navigate("/directorymir");
        }, 3000);
      } catch (error) {
        console.error("Error al guardar en la base de datos:", error);
        setSnackbarMessage("Error al guardar los cambios.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const generateAndDownloadPDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    let yOffset = 5;

    const logoWidthIzquierda = 28;
    const logoWidthDerecha = 50;
    const logoHeightIzquierda = 30;
    const logoHeightDerecha = 10;

    doc.addImage(
      logoIzquierda,
      "PNG",
      3,
      4,
      logoWidthIzquierda,
      logoHeightIzquierda
    );
    doc.addImage(
      logoDerecha,
      "PNG",
      pageWidth - logoWidthDerecha - 10,
      10,
      logoWidthDerecha,
      logoHeightDerecha
    );
    yOffset += Math.max(logoHeightIzquierda, logoHeightDerecha) + 5;

    const addCenteredText = (text, size, bold = false) => {
      doc.setFontSize(size);
      if (bold) doc.setFont("helvetica", "bold");
      else doc.setFont("helvetica", "normal");
      const textWidth =
        (doc.getStringUnitWidth(text) * size) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, yOffset);
      yOffset += 7;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 20;
      }
    };

    addCenteredText("H AYUNTAMIENTO DE XALISCO", 14, true);
    addCenteredText(`${dependenciaPrograma.toUpperCase()}"`, 14, true);
    addCenteredText("MATRIZ DE INDICADORES DE RESULTADOS", 14, true);
    addCenteredText("COMPROBANTE DE AVANCE", 14, true);
    yOffset += 10;

    const tableData = [
      ["Fecha", formattedDate],
      ["Hora", formattedTime],
      ["Usuario", name],
      ["Programa", nombrePrograma.toUpperCase()],
      ["Dependencia", dependenciaPrograma.toUpperCase()],
      ["Nombre MIR", nombreMIR || "No definido"],
      ["Mensaje", "¡Se ha agregado un avance exitosamente!"],
    ];

    doc.autoTable({
      startY: yOffset,
      head: [["Campo", "Valor"]],
      body: tableData,
      styles: { font: "helvetica", fontSize: 13 },
      headStyles: { fillColor: [0, 0, 0] },
      columnStyles: { 0: { cellWidth: 40 }, 1: { cellWidth: 140 } },
      margin: { top: 10 },
    });

    doc.save(`Comprobante_Avance_${nombrePrograma.toUpperCase()}.pdf`);
  };

  return (
    <div className="avance-mir-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="avance-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="zoom-in">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="avance-mir-title" data-aos="zoom-in">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>MATRIZ DE INDICADORES DE RESULTADOS AVANCES</h1>
        <h3>PROGRAMA: {mirData.nombrePrograma?.toUpperCase()}</h3>
        <h3>UNIDAD RESPONSABLE: {dependenciaPrograma.toUpperCase()}</h3>
      </div>
      <div className="avance-mir-content" data-aos="fade-left" delay="1000">
        {mirData ? (
          <div className="avance-mir-grid">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveToDatabase}
            >
              Agregar avance
            </Button>
            <div className="avance-mir-actividades">
              <h3>Actividades</h3>
              {Array.isArray(mirData?.actividades) &&
                mirData.actividades.map((actividad, index) => (
                  <Accordion key={index} className="avance-mir-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="mir-accordion-summary"
                    >
                      <Typography>Actividad No. {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="mir-accordion-details">
                      <div className="actividad">
                        <div className="mir-grid-item">
                          <strong> Nombre: </strong>
                          {actividad.nombreActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Objetivo: </strong>
                          {actividad.objetivoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Fórmula: </strong>{" "}
                          {actividad.fórmulaActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Frecuencia y Tipo: </strong>{" "}
                          {actividad.frecuenciaTipoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Medios de verificación: </strong>{" "}
                          {actividad.mediosVerificaciónActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Supuestos: </strong>{" "}
                          {actividad.supuestosActividad || "N/A"}
                        </div>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              <EditableField
                                value={
                                  actividad.unidadMedidaActividad.unidadMedida
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "unidadMedida", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              <EditableField
                                value={
                                  actividad.unidadMedidaActividad.frecuencia
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "frecuencia", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              <EditableField
                                value={actividad.tipoActividad.tipo}
                                onSave={(newValue) =>
                                  handleSave(index, "tipo", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              <EditableField
                                value={actividad.tipoActividad.dimensión}
                                onSave={(newValue) =>
                                  handleSave(index, "dimensión", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mir-grid-item">
                          <strong> Sentido: </strong>
                          <EditableField
                            value={actividad.sentidoActividad}
                            onSave={(newValue) =>
                              handleSave(index, "sentidoActividad", newValue)
                            }
                            editable={isEditable}
                            isText={true}
                          />
                        </div>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Linea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              <EditableField
                                value={
                                  actividad.lineaBaseActividad.añoLineaBase
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "añoLineaBase", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              <EditableField
                                value={actividad.lineaBaseActividad.lineaBase}
                                onSave={(newValue) =>
                                  handleSave(index, "lineaBase", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  actividad.metasActividades.datosVariables1
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "datosVariables1", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  actividad.metasActividades.datosVariables2
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "datosVariables2", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.metasActividades.resultado}
                                onSave={(newValue) =>
                                  handleSave(index, "resultado", newValue)
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.primerTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestre.datos1",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.primerTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestre.datos2",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.primerTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestre.resultado",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.segundoTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestre.datos1",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.segundoTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestre.datos2",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.segundoTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestre.resultado",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.tercerTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestre.datos1",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.tercerTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestre.datos2",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.tercerTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestre.resultado",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.cuartoTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestre.datos1",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.cuartoTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestre.datos2",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.cuartoTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestre.resultado",
                                    newValue
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        ) : (
          <p>Aún no se ha agregado ningún Programa Anual de Trabajo</p>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AvanceMIR;
