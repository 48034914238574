import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
} from "@mui/material";
import Header from "./Components/Header.js";
import "./Css/EjesRectoresMIR.css";
import { db } from "./firebase.js";
import { collection, query, getDocs, doc, getDoc } from "firebase/firestore";

function EjeR4MIR() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [programasPresupuestarioData, setProgramasPresupuestarioData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDependenciaPrograma, setSelectedDependenciaPrograma] = useState("");
  const [selectedDocPath, setSelectedDocPath] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [ejeRector, setEjeRector] = useState("");
  const [temaDesarrollo, setTemaDesarrollo] = useState("");
  const [objetivoPrograma, setObjetivoPrograma] = useState("");
  const [nombrePrograma, setProgramaPresupuestario] = useState("");
  const [mirListDialogOpen, setMirListDialogOpen] = useState(false);
  const [mirDocuments, setMirDocuments] = useState([]);
  const [menuAction, setMenuAction] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }

    const fetchData = async () => {
      const qE4Mir = query(collection(db, "EjeRector4Mir", "E4-MIR", "ProgramasPresupuestarios"));
      const querySnapshotE4Mir = await getDocs(qE4Mir);
      const dataE4Mir = querySnapshotE4Mir.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataE4Mir.sort((a, b) => a.nombrePrograma.localeCompare(b.nombrePrograma));
      setProgramasPresupuestarioData(dataE4Mir);
      setFilteredData(dataE4Mir); 
    };

    fetchData();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const handleListClick = async (event, dependenciaPrograma, docId) => {
    try {
      setSelectedDependenciaPrograma(dependenciaPrograma);
      setSelectedDocPath(`EjeRector4Mir/E4-MIR/ProgramasPresupuestarios/${docId}`);
      setAnchorEl(event.currentTarget);
  
      const docRef = doc(db, `EjeRector4Mir/E4-MIR/ProgramasPresupuestarios`, docId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
  
        // Asignar todos los campos correctamente
        setEjeRector(data.ejeRector || ""); 
        setTemaDesarrollo(data.temaDesarrollo || ""); 
        setObjetivoPrograma(data.objetivoPrograma || ""); 
        setProgramaPresupuestario(data.nombrePrograma || ""); // nombrePrograma
  
        console.log("Datos obtenidos:", data); // Para depuración
      } else {
        console.warn("El documento no existe.");
      }
    } catch (error) {
      console.error("Error al obtener los datos del documento:", error);
    }
  };
  

  const handleMenuClose = async (action) => {
    setAnchorEl(null);
    setMenuAction(action); 
    if (action === "add") {
      navigate("/formulario-mir", {
        state: {
          dependenciaPrograma: selectedDependenciaPrograma,
          docPath: selectedDocPath,
          ejeRector,
          temaDesarrollo,
          objetivoPrograma,
          nombrePrograma
        },
      });
    } else if (action === "view" || action === "progress") {
      const mirRef = collection(db, selectedDocPath, "Mir");
      const mirSnapshot = await getDocs(mirRef);
      const mirDocuments = mirSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (mirDocuments.length === 0) {
        setSnackbarOpen(true);
      } else if (mirDocuments.length === 1) {
        const mirId = mirDocuments[0].id;
        const route =
          action === "view" ? `/ver-mir/${mirId}` : `/avance-mir/${mirId}`;
        navigate(route, {
          state: { dependenciaPrograma: selectedDependenciaPrograma, docPath: selectedDocPath },
        });
      } else if (mirDocuments.length > 1) {
        setMirDocuments(mirDocuments);
        setMirListDialogOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleMirListItemClick = (mirId) => {
    if (menuAction === "view") {
      navigate(`/ver-mir/${mirId}`, {
        state: {
          dependenciaPrograma: selectedDependenciaPrograma,
          docPath: selectedDocPath,
          ejeRector,
          temaDesarrollo,
          objetivoPrograma,
          nombrePrograma
        },
      });
    } else if (menuAction === "progress") {
      navigate(`/avance-mir/${mirId}`, {
        state: {
          dependenciaPrograma: selectedDependenciaPrograma,
          docPath: selectedDocPath,
          ejeRector,
          temaDesarrollo,
          objetivoPrograma,
          nombrePrograma
        },
      });
    }
    setMirListDialogOpen(false);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = programasPresupuestarioData.filter(
      (item) =>
        item.nombrePrograma.toLowerCase().includes(value) ||
        item.dependenciaPrograma.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  return (
    <div className="ejes-rectores-mir-page">
      <Header name={name} handleLogout={handleLogout} />
      <div className="ejes-rectores-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="ejes-rectores-mir-content-container" data-aos="fade-up">
        <h1>EJE RECTOR 4. SEGURIDAD: MUNICIPIO CON SEGURIDAD Y JUSTICIA</h1>
        <TextField
          fullWidth
          placeholder="Buscar por Programa Presupuestario o Dependencia"
          value={searchTerm}
          onChange={handleSearchChange}
          margin="normal"
          variant="outlined"
        />
        <div className="ejes-rectores-mir-card-container">
          {filteredData.map((item, index) => (
            <div
              className="ejes-rectores-mir-card"
              key={index}
              onClick={(e) => handleListClick(e, item.dependenciaPrograma, item.id)}
            >
              <h2>{item.nombrePrograma}</h2>
              <p>{item.dependenciaPrograma}</p>
            </div>
          ))}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(null)}
      >
        <MenuItem onClick={() => handleMenuClose("view")}>
          Ver Matriz de Indicadores de Resultados
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("add")}>
          Agregar Matriz de Indicadores de Resultados
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("progress")}>
          Agregar Avance a la Matriz de Indicadores de Resultados
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(null)}>Cancelar</MenuItem>
      </Menu>
      <Dialog
        open={mirListDialogOpen}
        onClose={() => setMirListDialogOpen(false)}
      >
        <DialogTitle>
          Selecciona la Matriz de Indicadores de Resultados que desea ver:
        </DialogTitle>
        <DialogContent>
          <List>
            {mirDocuments.map((mir) => (
              <ListItem
                button
                key={mir.id}
                onClick={() => handleMirListItemClick(mir.id)}
              >
                <ListItemText primary={mir.nombreMIR} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMirListDialogOpen(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="warning">
          No hay ninguna MIR en este Programa.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EjeR4MIR;
