import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "./firebase.js";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import Header from "./Components/Header.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Css/VerMir.css";
import { ArrowBack } from "@mui/icons-material";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/LogoImplanNuevo.jpeg";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import jsPDF from "jspdf";
import "jspdf-autotable";

function VerMIR() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependenciaPrograma, docPath } = location.state || {};
  const { mirId } = useParams();
  const [mirData, setMirData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }
    const fetchMirData = async () => {
      if (docPath && mirId) {
        const patDocRef = doc(db, `${docPath}/Mir`, mirId);
        const patDocSnap = await getDoc(patDocRef);
        if (patDocSnap.exists()) {
          setMirData({ id: patDocSnap.id, ...patDocSnap.data() });
        }
      }
    };

    fetchMirData();
  }, [docPath, mirId, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const generatePDF = async () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    let yOffset = -7;

    const logoWidthIzquierda = 28;
    const logoWidthDerecha = 50;
    const logoHeightIzquierda = 30;
    const logoHeightDerecha = 10;

    doc.addImage(
      logoIzquierda,
      "PNG",
      3,
      4,
      logoWidthIzquierda,
      logoHeightIzquierda
    );
    doc.addImage(
      logoDerecha,
      "PNG",
      pageWidth - logoWidthDerecha - 10,
      10,
      logoWidthDerecha,
      logoHeightDerecha
    );
    yOffset += Math.max(logoHeightIzquierda, logoHeightDerecha) + 5;

    const addCenteredText = (text, size, bold = false) => {
      doc.setFontSize(size);
      if (bold) doc.setFont("helvetica", "bold");
      else doc.setFont("helvetica", "normal");
      const textWidth =
        (doc.getStringUnitWidth(text) * size) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, yOffset);
      yOffset += 7;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 20;
      }
    };

    const addTable = (title, headers, data) => {
      yOffset += 10;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(title, 15, yOffset);
      yOffset += 5;

      doc.autoTable({
        startY: yOffset,
        head: [headers],
        body: [data],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 10;
      }
    };
    const addActivityTable = (actividad, index) => {
      if (index % 2 === 0 && index !== 0) {
        doc.addPage();
        yOffset = 20;
      }

      const actividadTitulo = `Actividad ${index + 1}`;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(actividadTitulo, 15, yOffset);
      yOffset += 3;

      const headers = [
        "Nombre",
        "Objetivo",
        "Fórmula",
        "Frecuencia y tipo",
        "Medios de verificación",
        "Supuestos",
      ];
      const data = [
        actividad.nombreActividad,
        actividad.objetivoActividad,
        actividad.fórmulaActividad,
        actividad.frecuenciaTipoActividad,
        actividad.mediosVerificaciónActividad,
        actividad.supuestosActividad,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [headers],
        body: [data],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      doc.setFontSize(10);
      doc.text("Unidad de Medida / Frecuencia", 15, yOffset);
      yOffset += 3;

      const unidadaMedidaHeaders = [
        "Unidad de medida",
        "Frecuencia de medición",
      ];
      const unidadaMedidaData = [
        actividad.unidadMedidaActividad.unidadMedida,
        actividad.unidadMedidaActividad.frecuencia,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [unidadaMedidaHeaders],
        body: [unidadaMedidaData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      doc.setFontSize(10);
      doc.text("Tipo / Dimensión", 15, yOffset);
      yOffset += 3;

      const tipoDimensionHeaders = ["Tipo", "Prueba"];
      const tipoDimensionData = [
        actividad.tipoActividad.tipo,
        actividad.tipoActividad.dimensión,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [tipoDimensionHeaders],
        body: [tipoDimensionData.map((d, i) => [d])],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      doc.setFontSize(10);
      doc.text("Sentido", 15, yOffset);
      yOffset += 3;

      const sentidoHeaders = [
        "Sentido",
      ];
      const sentidoData = [
        actividad.sentidoActividad,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [sentidoHeaders],
        body: [sentidoData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      doc.setFontSize(10);
      doc.text("Linea base", 15, yOffset);
      yOffset += 5;

      const lineaBaseHeaders = [
        "Año",
        "Linea base",
      ];
      const lineaBaseData = [
        actividad.lineaBaseActividad.añoLineaBase,
        actividad.lineaBaseActividad.lineaBase,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [lineaBaseHeaders],
        body: [lineaBaseData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      doc.setFontSize(10);
      doc.text("Metas", 15, yOffset);
      yOffset += 5;

      const metasHeaders = [
        "Dato 1",
        "Dato 2",
        "Resultado",
      ];
      const metasData = [
        actividad.metasActividades.datosVariables1,
        actividad.metasActividades.datosVariables2,
        actividad.metasActividades.resultado,
      ];
      doc.autoTable({
        startY: yOffset,
        head: [metasHeaders],
        body: [metasData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 3;
      yOffset += 3;

      const primerTrimestreHeaders = [
        "Dato 1 1er trimestre",
        "Dato 2 1er trimestre",
        "Resultado 1er trimestre",
      ];
      const primerTrimestreData = [
        actividad.primerTrimestre.datos1,
        actividad.primerTrimestre.datos2,
        actividad.primerTrimestre.resultado,
      ];
      doc.autoTable({
        startY: yOffset,
        head: [primerTrimestreHeaders],
        body: [primerTrimestreData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 3;
      yOffset += 3;

      const segundoTrimestreHeaders = [
        "Dato 1 2do trimestre",
        "Dato 2 2do trimestre",
        "Resultado 2do trimestre",
      ];
      const segundoTrimestreData = [
        actividad.segundoTrimestre.datos1,
        actividad.segundoTrimestre.datos2,
        actividad.segundoTrimestre.resultado,
      ];
      doc.autoTable({
        startY: yOffset,
        head: [segundoTrimestreHeaders],
        body: [segundoTrimestreData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 3;
      yOffset += 3;

      const tercerTrimestreHeaders = [
        "Dato 1 3er trimestre",
        "Dato 2 3er trimestre",
        "Resultado 3er trimestre",
      ];
      const tercerTrimestreData = [
        actividad.tercerTrimestre.datos1,
        actividad.tercerTrimestre.datos2,
        actividad.tercerTrimestre.resultado,
      ];
      doc.autoTable({
        startY: yOffset,
        head: [tercerTrimestreHeaders],
        body: [tercerTrimestreData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 3;
      yOffset += 3;

      const cuartoTrimestreHeaders = [
        "Dato 1 4to trimestre",
        "Dato 2 4to trimestre",
        "Resultado 4to trimestre",
      ];
      const cuartoTrimestreData = [
        actividad.cuartoTrimestre.datos1,
        actividad.cuartoTrimestre.datos2,
        actividad.cuartoTrimestre.resultado,
      ];
      doc.autoTable({
        startY: yOffset,
        head: [cuartoTrimestreHeaders],
        body: [cuartoTrimestreData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 7;
    };
    addCenteredText("H AYUNTAMIENTO DE XALISCO", 12, true);
    addCenteredText(`"${dependenciaPrograma.toUpperCase()}"`, 10, true);
    addCenteredText("MATRIZ DE INDICADORES DE RESULTADOS", 10, true);

    if (mirData) {
      addTable(
        "Datos del programa",
        [
          "Programa presupuestario",
          "Objetivo",
          "Dependencia general",
          "Eje Rector o Eje Transversal",
          "Tema de desarrollo",
        ],
        [
          mirData.nombrePrograma,
          mirData.objetivoPrograma,
          mirData.dependenciaPrograma,
          mirData.ejeRector,
          mirData.temaDesarrollo,
        ]
      );

      addTable(
        "Renglón fin",
        [
          "Objetivo o resumen narrativo",
          "Nombre",
          "Fórmula",
          "Frecuencia y Tipo",
          "Medios de verificación",
          "Supuestos",
        ],
        [
          mirData.objetivoRenglónFin,
          mirData.nombreRenglónFin,
          mirData.fórmulaRenglónFin,
          mirData.frecuenciaTipoRenglónFin,
          mirData.mediosVerificaciónRenglónFin,
          mirData.supuestosRenglónFin,
        ]
      );

      addTable(
        "Renglón propósito",
        [
          "Objetivo o resumen narrativo",
          "Nombre",
          "Fórmula",
          "Frecuencia y Tipo",
          "Medios de verificación",
          "Supuestos",
        ],
        [
          mirData.objetivoRenglónPropósito,
          mirData.nombreRenglónPropósito,
          mirData.fórmulaRenglónPropósito,
          mirData.frecuenciaTipoRenglónPropósito,
          mirData.mediosVerificaciónRenglónPropósito,
          mirData.supuestosRenglónPropósito,
        ]
      );

      // Iterar sobre las posiciones del arreglo (asumiendo que todos los arreglos tienen la misma longitud)
      const maxTables = Math.max(
        mirData.objetivoRenglónComponente.length,
        mirData.nombreRenglónComponente.length,
        mirData.fórmulaRenglónComponente.length,
        mirData.frecuenciaTipoRenglónComponente.length,
        mirData.mediosVerificaciónRenglónComponente.length,
        mirData.supuestosRenglónComponente.length
      );

      for (let i = 0; i < maxTables; i++) {
        // Obtener los datos de la posición `i` de cada arreglo
        const objetivo = mirData.objetivoRenglónComponente[i] || "";
        const nombre = mirData.nombreRenglónComponente[i] || "";
        const fórmula = mirData.fórmulaRenglónComponente[i] || "";
        const frecuenciaTipo = mirData.frecuenciaTipoRenglónComponente[i] || "";
        const mediosVerificación =
          mirData.mediosVerificaciónRenglónComponente[i] || "";
        const supuestos = mirData.supuestosRenglónComponente[i] || "";

        // Crear una tabla para la posición `i`
        addTable(
          `Renglón componentes ${i + 1}`, // Título de la tabla
          [
            "Objetivo o resumen narrativo",
            "Nombre",
            "Fórmula",
            "Frecuencia y Tipo",
            "Medios de verificación",
            "Supuestos",
          ], // Encabezados
          [
            objetivo,
            nombre,
            fórmula,
            frecuenciaTipo,
            mediosVerificación,
            supuestos,
          ] // Datos de la fila
        );
      }

      // Agregar firmas
      const signatureYOffset = yOffset + 40;
      const signatureLineLengthResponsable = 60;
      const signatureLineLengthTitular = 70;
      const signatureTextOffset = 5;
      const minimumSpaceBetweenSignatures = 45;

      // Responsable Elaboración
      const responsableXPosition = 15;

      doc.line(
        responsableXPosition,
        signatureYOffset,
        responsableXPosition + signatureLineLengthResponsable,
        signatureYOffset
      );
      doc.setFontSize(10);
      doc.text(
        mirData.responsableElaboracion,
        responsableXPosition,
        signatureYOffset + signatureTextOffset
      );
      doc.text(
        "Responsable Elaboración",
        responsableXPosition,
        signatureYOffset + signatureTextOffset + 5
      );

      // Titular Dependencia
      const titularXPosition =
        responsableXPosition +
        signatureLineLengthResponsable +
        minimumSpaceBetweenSignatures;

      doc.line(
        titularXPosition,
        signatureYOffset,
        titularXPosition + signatureLineLengthTitular,
        signatureYOffset
      );
      doc.text(
        mirData.titularDependencia,
        titularXPosition,
        signatureYOffset + signatureTextOffset
      );
      doc.text(
        "Titular Dependencia",
        titularXPosition,
        signatureYOffset + signatureTextOffset + 5
      );

      doc.addPage();
      yOffset = 20;

      mirData.actividades.forEach((actividad, index) => {
        addActivityTable(actividad, index);
      });
    }

    doc.save(
      `MIR ${mirData.nombrePrograma.toUpperCase()}.pdf`
    );
  };

  const deleteMIR = async () => {
    if (name !== mirData.userName) {
      setSnackbarMessage("ESTE USUARIO NO PUEDE ELIMINAR ESTA MATRIZ DE INDICADORES DE RESULTADOS");
      setSnackbarOpen(true);
      return;
    }
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (docPath && mirId) {
      const patDocRef = doc(db, `${docPath}/Mir`, mirId);
      await deleteDoc(patDocRef);
      setSuccessSnackbarOpen(true);
      setTimeout(() => {
        navigate("/directorymir");
      }, 4000);
    }
    setConfirmDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  return (
    <div className="ver-mir-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="ver-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="zoom-in">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="formulario-mir-title" data-aos="zoom-in">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>MATRIZ DE INDICADORES DE RESULTADOS</h1>
        <h3>PROGRAMA: {mirData.nombrePrograma?.toUpperCase()}</h3>
        <h3>UNIDAD RESPONSABLE: {dependenciaPrograma.toUpperCase()}</h3>
      </div>
      <div
        className="ver-mir-content"
        id="pdf-content"
        data-aos="fade-left"
        delay="1000"
      >
        {mirData ? (
          <div className="mir-grid">
            <Button
              variant="contained"
              color="primary"
              onClick={generatePDF}
              data-aos="zoom-in"
            >
              GENERAR MIR EN PDF
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={deleteMIR}
              data-aos="zoom-in"
            >
              ELIMINAR MIR
            </Button>
            <div className="mir-category">
              <h3>Datos del programa</h3>
              <div className="mir-table">
                <div className="mir-table-header">
                  <div className="mir-grid-header">Programa presupuestario</div>
                  <div className="mir-grid-header">Objetivo</div>
                  <div className="mir-grid-header">Dependencia general</div>
                  <div className="mir-grid-header">
                    Eje Rector o Eje Transversal
                  </div>
                  <div className="mir-grid-header">Tema de desarrollo</div>
                </div>
                <div className="mir-table-row">
                  <div className="mir-grid-item">{mirData.nombrePrograma}</div>
                  <div className="mir-grid-item">
                    {mirData.objetivoPrograma}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.dependenciaPrograma}
                  </div>
                  <div className="mir-grid-item">{mirData.ejeRector}</div>
                  <div className="mir-grid-item">{mirData.temaDesarrollo}</div>
                </div>
              </div>
            </div>
            <div className="mir-category">
              <h3>Renglón Fin</h3>
              <div className="mir-table-renglones">
                <div className="mir-table-header">
                  <div className="mir-grid-header">
                    Objetivo o resumen narrativo
                  </div>
                  <div className="mir-grid-header">Nombre</div>
                  <div className="mir-grid-header">Fórmula</div>
                  <div className="mir-grid-header">Frecuencia y Tipo</div>
                  <div className="mir-grid-header">Medios de verificación</div>
                  <div className="mir-grid-header">Supuestos</div>
                </div>
                <div className="mir-table-row">
                  <div className="mir-grid-item">
                    {mirData.objetivoRenglónFin}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.nombreRenglónFin}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.fórmulaRenglónFin}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.frecuenciaTipoRenglónFin}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.mediosVerificaciónRenglónFin}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.supuestosRenglónFin}
                  </div>
                </div>
              </div>
            </div>
            <div className="mir-category">
              <h3>Renglón Propósito</h3>
              <div className="mir-table-renglones">
                <div className="mir-table-header">
                  <div className="mir-grid-header">
                    Objetivo o resumen narrativo
                  </div>
                  <div className="mir-grid-header">Nombre</div>
                  <div className="mir-grid-header">Fórmula</div>
                  <div className="mir-grid-header">Frecuencia y Tipo</div>
                  <div className="mir-grid-header">Medios de verificación</div>
                  <div className="mir-grid-header">Supuestos</div>
                </div>
                <div className="mir-table-row">
                  <div className="mir-grid-item">
                    {mirData.objetivoRenglónPropósito}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.nombreRenglónPropósito}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.fórmulaRenglónPropósito}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.frecuenciaTipoRenglónPropósito}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.mediosVerificaciónRenglónPropósito}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.supuestosRenglónPropósito}
                  </div>
                </div>
              </div>
            </div>
            <div className="mir-category">
              <h3>Renglón Componentes</h3>
              {Array.isArray(mirData?.objetivoRenglónComponente) &&
                mirData.objetivoRenglónComponente.map((_, index) => (
                  <React.Fragment key={index}>
                    <h4 style={{ marginTop: "20px", fontWeight: "bold" }}>
                      Componente {index + 1}
                    </h4>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {mirData.objetivoRenglónComponente[index]}
                        </div>
                        <div className="mir-grid-item">
                          {mirData.nombreRenglónComponente?.[index] || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          {mirData.fórmulaRenglónComponente?.[index] || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          {mirData.frecuenciaTipoRenglónComponente?.[index] ||
                            "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          {mirData.mediosVerificaciónRenglónComponente?.[
                            index
                          ] || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          {mirData.supuestosRenglónComponente?.[index] || "N/A"}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
            <div className="mir-category">
              <h3>Responsable y Titular de la dependencia</h3>
              <div className="mir-table-responsable">
                <div className="mir-table-header">
                  <div className="mir-grid-header">
                    Responsable de la elaboración
                  </div>
                  <div className="mir-grid-header">
                    Titular de la dependencia
                  </div>
                </div>
                <div className="mir-table-row">
                  <div className="mir-grid-item">
                    {mirData.userName}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.titularDependencia}
                  </div>
                </div>
              </div>
            </div>
            <div className="mir-category-actividades">
            <h3>Actividades</h3>
              {Array.isArray(mirData?.actividades) &&
                mirData.actividades.map((actividad, index) => (
                  <Accordion key={index} className="avance-mir-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="mir-accordion-summary"
                    >
                      <Typography>Actividad No. {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="mir-accordion-details">
                      <div className="actividad">
                        <div className="mir-grid-item">
                          <strong> Nombre: </strong>
                          {actividad.nombreActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Objetivo: </strong>
                          {actividad.objetivoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Fórmula: </strong>{" "}
                          {actividad.fórmulaActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Frecuencia y Tipo: </strong>{" "}
                          {actividad.frecuenciaTipoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Medios de verificación: </strong>{" "}
                          {actividad.mediosVerificaciónActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Supuestos: </strong>{" "}
                          {actividad.supuestosActividad || "N/A"}
                        </div>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              {actividad.unidadMedidaActividad.unidadMedida}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              {actividad.unidadMedidaActividad.frecuencia}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              {actividad.tipoActividad.tipo}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dimensión: </strong>
                              {actividad.tipoActividad.dimensión}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mir-grid-item">
                          <strong> Sentido: </strong>
                          {actividad.sentidoActividad}
                        </div>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Linea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              {actividad.lineaBaseActividad.añoLineaBase}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              {actividad.lineaBaseActividad.lineaBase}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.metasActividades.datosVariables1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.metasActividades.datosVariables2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.metasActividades.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.primerTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.primerTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.primerTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.segundoTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.segundoTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.segundoTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.tercerTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.tercerTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.tercerTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.cuartoTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.cuartoTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.cuartoTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        ) : (
          <p>Aún no se ha agregado ninguna matriz de indicadores de resultados</p>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Matriz de indicadores de resultados eliminada correctamente
        </Alert>
      </Snackbar>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea eliminar esta matriz de indicadores de resultados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VerMIR;
