import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { db } from "./firebase";
import "./Css/FormularioMIR.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, addDoc } from "firebase/firestore";
import Header from "./Components/Header.js";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Snackbar,
  Alert,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/LogoImplanNuevo.jpeg";

function FormularioMIR() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const {
    dependenciaPrograma,
    docPath,
    ejeRector,
    temaDesarrollo,
    objetivoPrograma,
    nombrePrograma,
  } = location.state;

  const [formData, setFormData] = useState({
    /* Datos del programa*/
    dependenciaPrograma: dependenciaPrograma || "",
    nombrePrograma: nombrePrograma || "",
    ejeRector: ejeRector || "",
    temaDesarrollo: temaDesarrollo || "",
    objetivoPrograma: objetivoPrograma || "",

    /* Datos del renglón fin*/
    objetivoRenglónFin: "",
    nombreRenglónFin: "",
    fórmulaRenglónFin: "",
    frecuenciaTipoRenglónFin: "",
    mediosVerificaciónRenglónFin: "",
    supuestosRenglónFin: "",

    /* Datos del renglón propósito*/
    objetivoRenglónPropósito: "",
    nombreRenglónPropósito: "",
    fórmulaRenglónPropósito: "",
    frecuenciaTipoRenglónPropósito: "",
    mediosVerificaciónRenglónPropósito: "",
    supuestosRenglónPropósito: "",

    /* Datos del renglón componentes*/
    objetivoRenglónComponente: [],
    nombreRenglónComponente: [],
    fórmulaRenglónComponente: [],
    frecuenciaTipoRenglónComponente: [],
    mediosVerificaciónRenglónComponente: [],
    supuestosRenglónComponente: [],

    /* Datos de las actividades */
    actividades: [],

    /* Datos de los responsables */
    responsableElaboracion: "",
    titularDependencia: "",
    userName: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [nombreMIR, setNombreMIR] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
      setFormData((prevData) => ({
        ...prevData,
        userName: storedName,
      }));
    } else {
      navigate("/login");
    }

    setFormData((prevData) => ({
      ...prevData,
      ejeRector: ejeRector || "",
      temaDesarrollo: temaDesarrollo || "",
      objetivoPrograma: objetivoPrograma || "",
    }));

    const fetchData = async () => {};

    fetchData();
  }, [
    navigate,
    dependenciaPrograma,
    docPath,
    ejeRector,
    temaDesarrollo,
    objetivoPrograma,
  ]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setConfirmDialogOpen(false);
    e.preventDefault();
    try {
      const mirCollectionRef = collection(db, `${docPath}/Mir`);
      await addDoc(mirCollectionRef, { ...formData, nombreMIR });
      setSnackbarMessage(
        "¡MATRIZ DE INDICADORES DE RESULTADOS (MIR) guardado exitosamente!"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/directorymir");
      }, 3200);
    } catch (error) {
      console.error("Error guardando los datos: ", error);
      setSnackbarMessage("Error guardando los datos");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const agregarActividad = () => {
    setFormData((prevData) => ({
      ...prevData,
      actividades: [
        ...prevData.actividades,
        {
          objetivoActividad: "",
          nombreActividad: "",
          fórmulaActividad: "",
          frecuenciaTipoActividad: "",
          mediosVerificaciónActividad: "",
          supuestosActividad: "",
          unidadMedidaActividad: {
            unidadMedida: "",
            frecuencia: "",
          },
          tipoActividad: {
            tipo: "",
            dimensión: "",
          },
          sentidoActividad: "",
          lineaBaseActividad: {
            añoLineaBase: "",
            lineaBase: "",
          },
          metasActividades: {
            datosVariables1: "",
            datosVariables2: "",
            resultado: "",
          },
          primerTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          segundoTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          tercerTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          cuartoTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          justificaciónResultados: {
            análisis: "",
            mediosVerificación: "",
          },
          notasComplementarias: "",
        },
      ],
    }));
  };

  const eliminarActividad = (index) => {
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      nuevasActividades.splice(index, 1);
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleActividadChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      const keys = name.split(".");
      if (keys.length > 1) {
        // Campo anidado
        nuevasActividades[index] = {
          ...nuevasActividades[index],
          [keys[0]]: {
            ...nuevasActividades[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        // Campo simple
        nuevasActividades[index] = {
          ...nuevasActividades[index],
          [name]: value,
        };
      }
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleConfirmDialogOpen = () => {
    console.log("Abriendo diálogo...");
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleNombreMIRChange = (e) => {
    setNombreMIR(e.target.value);
  };

  const agregarRenglón = () => {
    setFormData((prevData) => ({
      ...prevData,
      objetivoRenglónComponente: [...prevData.objetivoRenglónComponente, ""],
      nombreRenglónComponente: [...prevData.nombreRenglónComponente, ""],
      fórmulaRenglónComponente: [...prevData.fórmulaRenglónComponente, ""],
      frecuenciaTipoRenglónComponente: [
        ...prevData.frecuenciaTipoRenglónComponente,
        "",
      ],
      mediosVerificaciónRenglónComponente: [
        ...prevData.mediosVerificaciónRenglónComponente,
        "",
      ],
      supuestosRenglónComponente: [...prevData.supuestosRenglónComponente, ""],
    }));
  };

  const eliminarRenglón = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      objetivoRenglónComponente: prevData.objetivoRenglónComponente.filter(
        (_, i) => i !== index
      ),
      nombreRenglónComponente: prevData.nombreRenglónComponente.filter(
        (_, i) => i !== index
      ),
      fórmulaRenglónComponente: prevData.fórmulaRenglónComponente.filter(
        (_, i) => i !== index
      ),
      frecuenciaTipoRenglónComponente:
        prevData.frecuenciaTipoRenglónComponente.filter((_, i) => i !== index),
      mediosVerificaciónRenglónComponente:
        prevData.mediosVerificaciónRenglónComponente.filter(
          (_, i) => i !== index
        ),
      supuestosRenglónComponente: prevData.supuestosRenglónComponente.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleRenglónChange = (e, index, campo) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [campo]: prevData[campo].map((item, i) => (i === index ? value : item)),
    }));
  };

  return (
    <div className="formulario-mir-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="formulario-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="formulario-mir-title" data-aos="zoom-in-up">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>MATRIZ DE INDICADORES DE RESULTADOS</h1>
        <h3>PROGRAMA: {nombrePrograma.toUpperCase()}</h3>
        <h3>UNIDAD RESPONSABLE: {dependenciaPrograma.toUpperCase()}</h3>
      </div>
      <form
        onSubmit={handleSubmit}
        className="formulario-mir-form"
        data-aos="fade-up"
        delay="1000"
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RENGLÓN FIN</Typography>
          </AccordionSummary>
          <div className="columna">
            <label>OBJETIVO O RESUMEN NARRATIVO:</label>
            <textarea
              name="objetivoRenglónFin"
              value={formData.objetivoRenglónFin}
              onChange={handleChange}
              required
            />
            <Typography style={{ fontWeight: "bold" }}>INDICADORES</Typography>
            <label>NOMBRE:</label>
            <textarea
              name="nombreRenglónFin"
              value={formData.nombreRenglónFin}
              onChange={handleChange}
              required
            />
            <label>FÓRMULA:</label>
            <textarea
              name="fórmulaRenglónFin"
              value={formData.fórmulaRenglónFin}
              onChange={handleChange}
              required
            />
            <label>FRECUENCIA Y TIPO:</label>
            <textarea
              name="frecuenciaTipoRenglónFin"
              value={formData.frecuenciaTipoRenglónFin}
              onChange={handleChange}
              required
            />
            <label>MEDIOS DE VERIFICACIÓN:</label>
            <textarea
              name="mediosVerificaciónRenglónFin"
              value={formData.mediosVerificaciónRenglónFin}
              onChange={handleChange}
              required
            />
            <label>SUPUESTOS:</label>
            <textarea
              name="supuestosRenglónFin"
              value={formData.supuestosRenglónFin}
              onChange={handleChange}
              required
            />
          </div>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RENGLÓN PROPÓSITO</Typography>
          </AccordionSummary>
          <div className="columna">
            <label>OBJETIVO O RESUMEN NARRATIVO:</label>
            <textarea
              name="objetivoRenglónPropósito"
              value={formData.objetivoRenglónPropósito}
              onChange={handleChange}
              required
            />
            <Typography style={{ fontWeight: "bold" }}>INDICADORES</Typography>
            <label>NOMBRE:</label>
            <textarea
              name="nombreRenglónPropósito"
              value={formData.nombreRenglónPropósito}
              onChange={handleChange}
              required
            />
            <label>FÓRMULA:</label>
            <textarea
              name="fórmulaRenglónPropósito"
              value={formData.fórmulaRenglónPropósito}
              onChange={handleChange}
              required
            />
            <label>FRECUENCIA Y TIPO:</label>
            <textarea
              name="frecuenciaTipoRenglónPropósito"
              value={formData.frecuenciaTipoRenglónPropósito}
              onChange={handleChange}
              required
            />
            <label>MEDIOS DE VERIFICACIÓN:</label>
            <textarea
              name="mediosVerificaciónRenglónPropósito"
              value={formData.mediosVerificaciónRenglónPropósito}
              onChange={handleChange}
              required
            />
            <label>SUPUESTOS:</label>
            <textarea
              name="supuestosRenglónPropósito"
              value={formData.supuestosRenglónPropósito}
              onChange={handleChange}
              required
            />
          </div>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RENGLÓN COMPONENTES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.objetivoRenglónComponente.map((_, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Componente No. {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>Objetivo:</label>
                      <textarea
                        type="text"
                        value={formData.objetivoRenglónComponente[index]}
                        onChange={(e) =>
                          handleRenglónChange(
                            e,
                            index,
                            "objetivoRenglónComponente"
                          )
                        }
                      />
                      <label>Nombre:</label>
                      <textarea
                        type="text"
                        value={formData.nombreRenglónComponente[index]}
                        onChange={(e) =>
                          handleRenglónChange(
                            e,
                            index,
                            "nombreRenglónComponente"
                          )
                        }
                      />
                      <label>Fórmula:</label>
                      <textarea
                        type="text"
                        value={formData.fórmulaRenglónComponente[index]}
                        onChange={(e) =>
                          handleRenglónChange(
                            e,
                            index,
                            "fórmulaRenglónComponente"
                          )
                        }
                      />
                      <label>Frecuencia:</label>
                      <textarea
                        type="text"
                        value={formData.frecuenciaTipoRenglónComponente[index]}
                        onChange={(e) =>
                          handleRenglónChange(
                            e,
                            index,
                            "frecuenciaTipoRenglónComponente"
                          )
                        }
                      />
                      <label>Medios de Verificación:</label>
                      <textarea
                        type="text"
                        value={
                          formData.mediosVerificaciónRenglónComponente[index]
                        }
                        onChange={(e) =>
                          handleRenglónChange(
                            e,
                            index,
                            "mediosVerificaciónRenglónComponente"
                          )
                        }
                      />
                      <label>Supuestos:</label>
                      <textarea
                        type="text"
                        value={formData.supuestosRenglónComponente[index]}
                        onChange={(e) =>
                          handleRenglónChange(
                            e,
                            index,
                            "supuestosRenglónComponente"
                          )
                        }
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-mir-buttons">
                <button type="button" onClick={agregarRenglón} className="btn">
                  Agregar otro componente
                </button>
                <button
                  type="button"
                  onClick={() =>
                    eliminarRenglón(
                      formData.objetivoRenglónComponente.length - 1
                    )
                  }
                  className="btn"
                >
                  Borrar último componente
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              MATRIZ DE INDICADORES DE RESULTADOS (ACTIVIDADES)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.actividades.map((actividad, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Actividad No. {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>OBJETIVO:</label>
                      <textarea
                        name="objetivoActividad"
                        value={actividad.objetivoActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>NOMBRE:</label>
                      <textarea
                        name="nombreActividad"
                        value={actividad.nombreActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>FÓRMULA:</label>
                      <textarea
                        name="fórmulaActividad"
                        value={actividad.fórmulaActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>FRECUENCIA Y TIPO:</label>
                      <textarea
                        name="frecuenciaTipoActividad"
                        value={actividad.frecuenciaTipoActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>MEDIOS DE VERIFICACIÓN:</label>
                      <textarea
                        name="mediosVerificaciónActividad"
                        value={actividad.mediosVerificaciónActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>SUPUESTOS:</label>
                      <textarea
                        name="supuestosActividad"
                        value={actividad.supuestosActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            U. DE MEDIDA / FRECUENCIA DE MEDICIÓN
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="medida-frecuencia-container">
                            <label>U. DE MEDIDA:</label>
                            <textarea
                              name={`unidadMedidaActividad.unidadMedida`}
                              value={
                                actividad.unidadMedidaActividad.unidadMedida
                              }
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>FRECUENCIA DE MEDICIÓN:</label>
                            <textarea
                              name={`unidadMedidaActividad.frecuencia`}
                              value={actividad.unidadMedidaActividad.frecuencia}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>TIPO / DIMENSIÓN</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="tipo-dimension-container">
                            <label>TIPO:</label>
                            <textarea
                              name={`tipoActividad.tipo`}
                              value={actividad.tipoActividad.tipo}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>DIMENSIÓN:</label>
                            <textarea
                              name={`tipoActividad.dimensión`}
                              value={actividad.tipoActividad.dimensión}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <label>SENTIDO:</label>
                      <textarea
                        name="sentidoActividad"
                        value={actividad.sentidoActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                      />
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>LINEA BASE: AÑO / LB</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="tipo-dimension-container">
                            <label>AÑO:</label>
                            <textarea
                              name={`lineaBaseActividad.añoLineaBase`}
                              value={actividad.lineaBaseActividad.añoLineaBase}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>LÍNEA BASE:</label>
                            <textarea
                              name={`lineaBaseActividad.lineaBase`}
                              value={actividad.lineaBaseActividad.lineaBase}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>METAS: DATOS DE LAS VARIABLES</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="datos-variables-container">
                            <label>DATOS:</label>
                            <textarea
                              name={`metasActividades.datosVariables1`}
                              value={actividad.metasActividades.datosVariables1}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <textarea
                              name={`metasActividades.datosVariables2`}
                              value={actividad.metasActividades.datosVariables2}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>RESULTADO:</label>
                            <textarea
                              name={`metasActividades.resultado`}
                              value={actividad.metasActividades.resultado}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>1ER TRIMESTRE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="datos-container">
                            <label>DATOS:</label>
                            <textarea
                              name={`primerTrimestre.datos1`}
                              value={actividad.primerTrimestre.datos1}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <textarea
                              name={`primerTrimestre.datos2`}
                              value={actividad.primerTrimestre.datos2}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>RESULTADO:</label>
                            <textarea
                              name={`primerTrimestre.resultado`}
                              value={actividad.primerTrimestre.resultado}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>2DO TRIMESTRE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="datos-container">
                            <label>DATOS:</label>
                            <textarea
                              name={`segundoTrimestre.datos1`}
                              value={actividad.segundoTrimestre.datos1}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <textarea
                              name={`segundoTrimestre.datos2`}
                              value={actividad.segundoTrimestre.datos2}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>RESULTADO:</label>
                            <textarea
                              name={`segundoTrimestre.resultado`}
                              value={actividad.segundoTrimestre.resultado}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>3ER TRIMESTRE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="datos-container">
                            <label>DATOS:</label>
                            <textarea
                              name={`tercerTrimestre.datos1`}
                              value={actividad.tercerTrimestre.datos1}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <textarea
                              name={`tercerTrimestre.datos2`}
                              value={actividad.tercerTrimestre.datos2}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>RESULTADO:</label>
                            <textarea
                              name={`tercerTrimestre.resultado`}
                              value={actividad.tercerTrimestre.resultado}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>4TO TRIMESTRE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="datos-container">
                            <label>DATOS:</label>
                            <textarea
                              name={`cuartoTrimestre.datos1`}
                              value={actividad.cuartoTrimestre.datos1}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <textarea
                              name={`cuartoTrimestre.datos2`}
                              value={actividad.cuartoTrimestre.datos2}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>RESULTADO:</label>
                            <textarea
                              name={`cuartoTrimestre.resultado`}
                              value={actividad.cuartoTrimestre.resultado}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>JUSTIFICACIÓN DE RESULTADOS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="datos-container">
                            <label>
                              ANÁLISIS INICIAL E INTERPRETACIÓN DE RESULTADOS:
                            </label>
                            <textarea
                              name={`justificaciónResultados.análisis`}
                              value={actividad.justificaciónResultados.análisis}
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                            <label>MEDIO DE VERIFICACIÓN:</label>
                            <textarea
                              name={`justificaciónResultados.mediosVerificación`}
                              value={
                                actividad.justificaciónResultados
                                  .mediosVerificación
                              }
                              onChange={(e) => handleActividadChange(e, index)}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <label>NOTAS COMPLEMENTARIAS:</label>
                      <textarea
                        name="notasComplementarias"
                        value={actividad.notasComplementarias}
                        onChange={(e) => handleActividadChange(e, index)}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-mir-buttons">
                <div className="formulario-mir-add-activity">
                  <button type="button" onClick={agregarActividad}>
                    Agregar una actividad
                  </button>
                </div>
                <div className="formulario-mir-delete-activity">
                  <button type="button" onClick={eliminarActividad}>
                    Borrar una actividad
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RESPONSABLE Y TITULAR</Typography>
          </AccordionSummary>
          <div className="columna">
            <label>RESPONSABLE DE LA ELABORACIÓN:</label>
            <textarea
              name="responsableElaboracion"
              value={formData.responsableElaboracion}
              onChange={handleChange}
              required
            />
            <label>TITULAR DE LA DEPENDENCIA:</label>
            <textarea
              name="titularDependencia"
              value={formData.titularDependencia}
              onChange={handleChange}
              required
            />
            <label>USUARIO QUE CREA LA MIR:</label>
            <textarea
              name="nombreUsuario"
              value={formData.userName}
              onChange={handleChange}
              disabled
            />
          </div>
        </Accordion>
        <div className="formulario-mir-submit-container">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleConfirmDialogOpen();
            }}
          >
            GUARDAR MATRIZ DE INDICADORES DE RESULTADOS
          </button>
        </div>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>GUARDAR MATRIZ DE INDICADORES DE RESULTADOS</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "justify" }}>
            <Typography
              component="span"
              variant="body1"
              style={{ fontWeight: "bold" }}
            >
              ¿Está seguro de que desea guardar la MATRIZ DE INDICADORES DE
              RESULTADOS?
            </Typography>
            <br />
            Verifique bien los datos agregados a su MIR (Vinculaciones,
            clasificación, actividades, responsable y titular), ya que una vez
            guardado no podrá modificarlos después.
            <br />
          </DialogContentText>
          <TextField
            label="Guardar como... Ejemplo: (MATRIZ DE INDICADORES DE RESULTADOS 2024)"
            value={nombreMIR}
            onChange={handleNombreMIRChange}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormularioMIR;
